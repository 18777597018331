<template>
    <div>
      <b-row>
        <b-col cols="12">
          <b-card-body>
            <b-row>
              <b-col cols="2">
                <b-form-group label="From Date" label-for="From Date">
                  <b-form-datepicker
                    id="from-date"
                    label="label"
                    v-model="startDate"
                    class="mb-1"
                    placeholder="Start Date"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group label="To Date" label-for="To Date">
                  <b-form-datepicker
                    id="to-date"
                    label="label"
                    v-model="endDate"
                    class="mb-1"
                    placeholder="End Date"

                  />
                </b-form-group>
              </b-col>
  
              <b-col cols="2">
                <b-form-group label="Driver" label-for="Driver">
                  <v-select
                    v-model="driver"
                    label="label"
                    :options="option"
                    @input="onChange()"
                    placeholder="Select Driver"

                  />

                </b-form-group>
                  
              </b-col>
              
              <b-col cols="2">
                <b-form-group label="Status" label-for="Status">
                  <v-select
                    v-model="status"
                    label="label"
                    :options="optionStatus"
                    @input="onChangeStatus()"
                    placeholder="Select Status"

                  />

                </b-form-group>
                  
              </b-col>
  
              <b-col md="3" style="margin-top: 20px;">
                <div>
                    <b-button variant="primary" @click="filterAllData"
                      ><feather-icon icon="filterIcons" /> Filter</b-button
                    >
  
                    <b-button variant="info" @click="resetAllData" class="ml-1"
                      ><feather-icon icon="filterIcons" /> Reset</b-button
                    >
                </div>
              </b-col>
            </b-row>
  
            <div></div>
          </b-card-body>
          <b-card>
            <b-card-body class="p-0">
              <h3>Completed Requests</h3>
              <b-overlay :show="showUpdate" rounded="sm">
              <b-table
                striped
                hover
                responsive
                class="position-relative"
                :per-page="perPage"
                :current-page="currentPage"
                :items="history_list"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(action)="data">
                      <label class="switch">
                        <input type="checkbox" @click="toggleCheckbox" :checked="data.item.status"/>
                        <div class="slider round"></div>
                      </label>
                </template>
              </b-table>
            </b-overlay>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </template>
          
          <script>
  import {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormTimepicker,
    BFormDatepicker,
    BOverlay,

  } from "bootstrap-vue";
  import { required, min, confirmed } from "@validations";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { serverUri } from "@/config";
  import router from "@/router";
  import { getUserData } from "@/auth/utils";
  import flatPickr from "vue-flatpickr-component";
  import moment from "moment";
  import DateRangePicker from "vue2-daterange-picker";
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  import vSelect from "vue-select";
  import axios from "axios";
  
  export default {
    components: {
      BButton,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BTable,
      BCard,
      BCardHeader,
      BCardTitle,
      BCardBody,
      BFormCheckbox,
      BFormCheckboxGroup,
      ValidationProvider,
      ValidationObserver,
      ToastificationContent,
      BInputGroup,
      BInputGroupAppend,
      BFormTimepicker,
      BFormDatepicker,
      flatPickr,
      DateRangePicker,
      vSelect,
      BOverlay,

    },
    data() {
      return {
        checkbox: false,
        dateRange: "",
        date: "",
        out_date: "",
        userId: getUserData().id,
        clockId: router.currentRoute.params.id,
        clock_in: "",
        clock_out: "",
        companyId: getUserData().company_id,
        dateRange: {
          startDate: new Date().toDateString(),
          endDate: new Date().toDateString(),
        },
        status: "",
        startDate: "",
        endDate: "",
        perPage: 25,
        selectedDriver: "",
        driver: "",
        pageOptions: [5, 10, 15, 25],
        option: [],
        optionStatus:[],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: " ",
        history_list: [],
        filterOn: [],
        showUpdate: false,
        fields: [
          {
            key: "driver_name",
            label: "Driver",
            sortable: false,
            formatter: (value) => {
              return value.toUpperCase();
            },
          },
          {
            key: "from_date",
            label: "From Date",
            sortable: false,
            formatter: (value) => {
              return moment(value).format("MM/DD/YYYY");
            },
          },
          {
            key: "to_date",
            label: "To Date",
            sortable: false,
            formatter: (value) => {
              return moment(value).format("MM/DD/YYYY");
            },
          },
          {
            key: "notes",
            label: "Notes",
            sortable: false,
            formatter: (value) => {
              return value;
            },
          },
          {
            key: "status",
            label: "Status",
            sortable: false,
            formatter: (value) => {
               if(value == 1) 
              {
                return "Approved";
              } else if(value == 2) 
              {
                return "Denied";
              } else {
                return "Pending";
              }
            },
          },
          // {
          //   key: "action"
          // },
        ],
      };
    },
    mounted() {
      this.getRequestOffHistory();
    },
    computed: {},
    props: {},
    methods: {
      toggleCheckbox() {
        this.checkbox = !this.checkbox;
        this.$emit("setCheckboxVal", this.checkbox);
      },
  
      filterAllData() {
        this.showUpdate = true;
        this.getRequestOffHistory();
      },
  
      onChange() {
        this.selectedDriver = this.driver?.title;
      },

      onChangeStatus() {
        this.selectedStatus = this.status?.title;
      },
  
      resetAllData() {
        this.showUpdate = true;
        (this.startDate = ""),
          (this.endDate = ""),
          (this.driver = { title: "", label: "" }),
          (this.selectedDriver = ""),
          (this.selectedStatus = ""),
          this.getRequestOffHistory();
      },
      showToast(variant, title, text) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: "BellIcon",
            text: text,
            variant,
          },
        });
      },
  
      onUpdate() {},
  
      getRequestOffHistory() {
        this.$http
          .post(`${serverUri}/request-off-history`, {
            company_id:this.companyId,
            driver_id: this.selectedDriver == undefined ? '' : this.selectedDriver,
            status: this.selectedStatus == undefined ? '' : this.selectedStatus,
            start_date: this.startDate,
            end_date: this.endDate,
          })
          .then((response) => {
            this.data = response.data;
            if (response.data.status == true) {
              this.history_list = response.data.data;
            } else {
              this.history_list = [];
            }
            this.showUpdate = false;
          });
      },
  
      showToast(variant, title, text) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: "BellIcon",
            text: text,
            variant,
          },
        });
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
    },
    created() {
      axios
        .get(`${serverUri}/drivers/alldriversdata/${this.companyId}`)
        .then((res) => {
          res.data.map((item) => {
            this.option.push({ title: item.id, label: item.driver_name });
          });
        });

        // add status options
        this.optionStatus.push({ title: 1, label: 'Approved' },{title:2, label:'Denied'});
    },
  };
  </script>
          
          <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  
  .edit-header {
    display: flex;
    align-items: center;
  }
  </style><style>
  .permission {
    margin: 10px 0;
  }
  
  .permission div {
    margin: 2px 0;
  }
  button.btn.btn-danger {
    margin-left: 10px;
  }
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    display: none;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #f22323;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #101010;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  </style>
          